import styled from '@emotion/styled'
import { Skeleton as AntdSkeleton } from 'antd'
import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const SkeletonContainer = styled('div')`
  display: grid;
  grid-template-columns: 0.5fr 5fr;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  max-height: 109px;
  align-items: center;
  justify-items: center;
`

export const Skeleton = styled(AntdSkeleton)``

export const SkeletonAvatar = styled(AntdSkeleton.Avatar)``
