import { Container, Skeleton, SkeletonAvatar, SkeletonContainer } from './styles'
import { SearchStateEnum } from '@/components/feature/PolicyDetailPage/SearchPolicyBox/SearchPolicyBox.hook'
import NotificationTable from '@/components/feature/PolicyDetailPage/NotificationTable'

type Props = { isComponentLoading: boolean; searchState: SearchStateEnum }

function NotificationTab({ isComponentLoading, searchState }: Props) {
  const isShow = searchState === SearchStateEnum.SEARCHING || searchState === SearchStateEnum.FINISH
  return (
    <Container visible={isShow}>
      {isComponentLoading ? (
        <SkeletonContainer>
          <SkeletonAvatar size="large" active />
          <Skeleton paragraph={{ rows: 1 }} active />
        </SkeletonContainer>
      ) : (
        <NotificationTable />
      )}
    </Container>
  )
}

export default NotificationTab
