import { SearchInput, Card, SearchIcon } from './styles'
import Table from '@/components/common/Table'
import ErrorLogTableHook from '@/components/feature/DopaPage/ErrorLogTable/ErrorLogTable.hook'

export default function ErrorLogTable() {
  const { columns, errorLog, onTableChange, onSearch, handlePaginate } = ErrorLogTableHook()

  return (
    <Card>
      <SearchInput
        id="dopa-error-log-search"
        prefix={<SearchIcon />}
        placeholder="ค้นหาเลขบัตรประชาชน, ชื่อ, นามสกุล"
        onChange={onSearch}
      />
      <Table
        rowKey="id"
        data-testid="dopa-error-log-table"
        columns={columns}
        loading={errorLog.isFetching}
        dataSource={errorLog?.data?.data}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: errorLog.data?.meta.totalItems,
          current: errorLog.data?.meta.currentPage,
          defaultCurrent: 1,
          defaultPageSize: 10,
          pageSize: errorLog.data?.meta.itemsPerPage,
          onChange: (page, pageSize) => handlePaginate(page, pageSize),
        }}
        scroll={{ x: '150vw' }}
        onChange={onTableChange}
      />
    </Card>
  )
}
