import { useImportTviConnectCheckDevice } from '@/services/tviConnect/useImportTviConnectCheckDevice'
import { ImportTviConnectCheckDeviceInputType } from '@/services/tviConnect/useImportTviConnectCheckDevice/types'
import { useTviConnectCheckStatus } from '@/services/tviConnect/useTviConnectCheckStatus'
import { useUnregisterTviConnectDevice } from '@/services/tviConnect/useUnregisterTviConnectDevice'
import {
  UnregisterTviConnectDeviceInputType,
  UnregisterTviConnectDeviceResponseType,
} from '@/services/tviConnect/useUnregisterTviConnectDevice/types'
import { useCallback, useEffect, useState } from 'react'
import { TviConnectDeviceType } from './types'
import message from '@/libs/message'
import dayjs from 'dayjs'
import COMMON from '@/constant/COMMON'

const CheckTviConnectPageHook = () => {
  const [devices, setDevices] = useState<TviConnectDeviceType[]>([]) // for master data
  const [displayDevices, setDisplayDevices] = useState<TviConnectDeviceType[]>([]) // for display in table
  const [from, setFrom] = useState<Date | null>(null)
  const [isPolling, setIsPolling] = useState(false)
  const useImportDevice = useImportTviConnectCheckDevice()
  const useUnregisterDevice = useUnregisterTviConnectDevice()
  const useCheckStatus = useTviConnectCheckStatus({
    deviceIds: displayDevices.map((item) => item.deviceId).filter((item) => item) as string[],
    from: from || dayjs().tz(COMMON.ASIA_BANGKOK_TIMEZONE).toDate(),
  })
  const pollingInterval = 10000 // 10 seconds

  const UseCheckDevice = useCallback(() => {
    // Refetch query for get status of devices
    useCheckStatus.refetch()

    if (useCheckStatus.data) {
      const mappedData = displayDevices.map((item) => {
        const checkedDevice = useCheckStatus.data.find(
          (queryItem) => queryItem.deviceId === item.deviceId,
        )
        return {
          ...item,
          on: checkedDevice?.on,
          off: checkedDevice?.off,
        } as TviConnectDeviceType
      })

      setDisplayDevices(mappedData)
    }
  }, [displayDevices, useCheckStatus])

  const submitImportDevice = async (data: ImportTviConnectCheckDeviceInputType[]) => {
    useImportDevice.mutate(data)
  }

  const unregisterDevice = async () => {
    if (displayDevices.length > 0 && isPolling) {
      const unregisterDevices: UnregisterTviConnectDeviceInputType[] = displayDevices
        .filter((item) => !item.reason)
        .map((item) => ({
          deviceId: item.deviceId,
          imei: item.imei,
          serialNumber: item.serialNumber,
          reason: item.reason,
          on: item.on,
          off: item.off,
        }))

      useUnregisterDevice.mutate(unregisterDevices)
      setIsPolling(false)
    }
  }

  const removeDevice = (imei: string) => {
    const newDevices = devices.filter((item) => item.imei !== imei)
    const newDisplayDevices = displayDevices.filter((item) => item.imei !== imei)

    setDevices(newDevices)
    setDisplayDevices(newDisplayDevices)
  }

  const mapFailedDevice = (
    prev: TviConnectDeviceType[],
    failedData: UnregisterTviConnectDeviceResponseType[],
  ) => {
    const newDevices = prev.map((item) => {
      const failedItem = failedData.find((failed) => failed.deviceId === item.deviceId)
      if (failedItem) {
        return {
          ...item,
          resetReason: failedItem.reason,
        }
      }
      return item
    })

    return newDevices.filter((item) => item.resetReason)
  }

  // For start polling after devices are loaded
  useEffect(() => {
    if (displayDevices.length > 0 && !isPolling) {
      setIsPolling(true)
    }
  }, [displayDevices, isPolling])

  // For set devices after import device
  useEffect(() => {
    if (useImportDevice.data) {
      const mappedData = useImportDevice.data.map((item) => {
        return {
          ...item,
          on: false,
          off: false,
        }
      })
      setDevices((prev) => [...prev, ...mappedData]) // for master data
      setDisplayDevices((prev) => [...prev, ...mappedData]) // for display in table
      // set polling start time after import device
      if (!from) {
        setFrom(dayjs().tz(COMMON.ASIA_BANGKOK_TIMEZONE).toDate())
      }

      message.success('เพิ่มข้อมูลสำเร็จ')
    }
  }, [useImportDevice.data])

  // For set devices after unregister device if some devices has reason
  useEffect(() => {
    if (useUnregisterDevice.data) {
      const failedData = useUnregisterDevice.data.filter((item) => item.reason)
      const oldFailedData = displayDevices.filter((item) => item.reason)

      if (failedData.length > 0 || oldFailedData.length > 0) {
        setDevices((prev) => {
          const mappedData = mapFailedDevice(prev, failedData)
          const prevFailedData = prev.filter((item) => item.reason)

          return [...prevFailedData, ...mappedData]
        })
        setDisplayDevices((prev) => {
          const mappedData = mapFailedDevice(prev, failedData)
          const prevFailedData = prev.filter((item) => item.reason)

          return [...prevFailedData, ...mappedData]
        })

        message.warning('รีเซ็ทไม่สำเร็จบางรายการ')
        return
      }

      // success case
      setDevices([])
      setDisplayDevices([])
      setFrom(null)
      message.success('รีเซ็ทข้อมูลสำเร็จ')
    }
  }, [useUnregisterDevice.data])

  useEffect(() => {
    if (!isPolling) return // if not polling, do nothing

    if (displayDevices.length > 0 && from) {
      // set interval for polling from JavaScript
      const polling = setInterval(() => {
        UseCheckDevice()
      }, pollingInterval)

      return () => {
        clearInterval(polling)
      }
    }
  }, [UseCheckDevice, displayDevices.length, from, isPolling])

  return {
    devices,
    displayDevices,
    submitImportDevice,
    unregisterDevice,
    useImportDevice,
    setDisplayDevices,
    removeDevice,
  }
}

export default CheckTviConnectPageHook
