import { UploadOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import {
  Container,
  Input,
  ChooseFileButton,
  Upload,
  TextArea,
  Desc,
  Title,
  Block,
  TopContainerStyle,
  MiddleContainerStyle,
  InputNumber,
  FormItem,
} from './styles'
import DeferredDiscountModalHook from './DeferredDiscountModal.hook'
import { toThaiLocaleDateString, toThaiLocaleDateAndMonthRangeWithDash } from '@/libs/date'
import Modal from '@/components/common/Modal'

function DeferredDiscountModal() {
  const {
    deferredDiscountModal,
    onCloseModal,
    form,
    handleUploadProps,
    handleFormChange,
    disabledSave,
    onSubmit,
  } = DeferredDiscountModalHook()

  return (
    <Modal
      title="ขออนุโลมส่วนลด"
      open={deferredDiscountModal.open}
      onCancel={onCloseModal}
      cancelText="ยกเลิก"
      onOk={onSubmit}
      okText="บันทึก"
      width={640}
      okButtonProps={{ disabled: disabledSave }}
    >
      <Form layout="vertical" form={form} onFieldsChange={handleFormChange}>
        <Container className={TopContainerStyle}>
          <Block>
            <Title>ชื่อผู้ขอรับส่วนลด</Title>
            <Input
              disabled
              value={
                deferredDiscountModal?.activeHealthPolicyBilling?.activeHealthPolicy?.insuredName ||
                '-'
              }
            />
          </Block>
          <Block>
            <Title>เลขบัตรประชาชนหรือ Passport</Title>
            <Input
              disabled
              value={
                deferredDiscountModal?.activeHealthPolicyBilling?.activeHealthPolicy?.identityId ||
                '-'
              }
            />
          </Block>
          <Block>
            <Title>รอบบิลวันที่</Title>
            <Input
              disabled
              value={
                deferredDiscountModal?.activeHealthPolicyBilling?.startDate &&
                deferredDiscountModal?.activeHealthPolicyBilling?.billDate
                  ? toThaiLocaleDateAndMonthRangeWithDash(
                      deferredDiscountModal?.activeHealthPolicyBilling?.startDate,
                      deferredDiscountModal?.activeHealthPolicyBilling?.billDate,
                    )
                  : '-'
              }
            />
          </Block>
          <Block>
            <Title>วันที่ครบกำหนดชำระ</Title>
            <Input
              disabled
              value={
                deferredDiscountModal?.activeHealthPolicyBilling?.billDate
                  ? toThaiLocaleDateString({
                      date: deferredDiscountModal?.activeHealthPolicyBilling?.billDate,
                    })
                  : '-'
              }
            />
          </Block>
          <Block>
            <FormItem name="discount" label="ส่วนลดค่าเบี้ย (บาท)">
              <InputNumber placeholder="0" />
            </FormItem>
          </Block>
        </Container>
        <Container className={MiddleContainerStyle}>
          <Block>
            <FormItem label="รายละเอียด" name="reason">
              <TextArea placeholder="เขียนรายละเอียด" />
            </FormItem>
          </Block>
        </Container>
        <Container className={MiddleContainerStyle}>
          <FormItem label="รูปรายละเอียด" name="uploadFile">
            <Upload maxCount={1} {...handleUploadProps}>
              <ChooseFileButton icon={<UploadOutlined />}>เลือก</ChooseFileButton>
            </Upload>
          </FormItem>
          <Desc>รูปที่คุณจะอัปโหลด PNG, JPG, JPEG or PDF (สูงสุดไม่เกิน 10mb.)</Desc>
        </Container>
      </Form>
    </Modal>
  )
}

export default DeferredDiscountModal
