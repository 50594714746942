import { Card, SearchIcon, SearchInput } from './style'
import Table from '@/components/common/Table'
import { Row, Col, Segmented, DatePicker } from 'antd'
import NotificationTableHook from '@/components/feature/PolicyDetailPage/NotificationTable/NotificationTable.hook'
import { useRecoilValue } from 'recoil'
import policyDetailAtom from '@/recoil/policyDetail'

const { RangePicker } = DatePicker

export function NotificationTable() {
  const policyDetail = useRecoilValue(policyDetailAtom)
  const { columns, userNotification, onSearch, onTableChange, toggle, onDatePickerChange } =
    NotificationTableHook({
      userId: String(policyDetail?.id),
    })
  return (
    <Card>
      <Row>
        <Col span={6}>
          <SearchInput
            id="user-notification-search"
            prefix={<SearchIcon />}
            placeholder="ค้นหาเลขกรมธรรมม์"
            onKeyDown={onSearch}
          />
        </Col>
        <Col span={18}>
          <Row align="middle" justify="end">
            {/* Date picker part  */}
            <Col>
              <span style={{ marginRight: 8 }}>เลือกตามวันส่งแจ้งเตือน</span>
              <RangePicker style={{ width: '264px' }} onChange={onDatePickerChange} />
            </Col>
            {/* Preset period part */}
            <Col>
              <span style={{ marginRight: 8, marginLeft: 8 }}>แสดงข้อมูล</span>
              <Segmented
                options={[
                  { label: '90 วัน', value: '90DaysAgo' },
                  { label: 'ทั้งหมด', value: 'all' },
                ]}
                onChange={toggle('period')}
              />
            </Col>
            {/* Switch language part */}
            <Col>
              <span style={{ marginRight: 8, marginLeft: 8 }}>ข้อความที่ส่ง</span>
              <Segmented options={['TH', 'EN']} onChange={toggle('language')} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <Table
            rowKey="id"
            data-testid="user-notification-table"
            columns={columns}
            loading={userNotification.isFetching}
            dataSource={userNotification?.data?.data}
            pagination={{
              pageSizeOptions: [10, 25, 50, 100],
              showSizeChanger: true,
              total: userNotification.data?.meta.totalItems,
              current: userNotification.data?.meta.currentPage,
              defaultCurrent: 1,
              defaultPageSize: 10,
              pageSize: userNotification.data?.meta.itemsPerPage,
            }}
            onChange={(pagination, filters) => onTableChange(pagination, filters)}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default NotificationTable
