import {
  Bottom,
  Container,
  GreenButton,
  NbContainer,
  NumberBadge,
  SubTitle,
  TContainer,
  Title,
  Top,
} from './styles'
import { TviConnectDeviceType } from '../types'
import Modal from '@/components/common/Modal'
import ResetTviConnectSectionHook from './ResetTviConnectSection.hook'

const ResetTviConnectSection = ({
  unregisterDevice,
  devices,
}: {
  unregisterDevice: () => void
  devices: TviConnectDeviceType[]
}) => {
  const { openModal, setOpenModal, onUnregisterDevice } = ResetTviConnectSectionHook({
    unregisterDevice,
  })
  return (
    <Container>
      <Top>
        <NbContainer>
          <NumberBadge>3</NumberBadge>
        </NbContainer>
        <TContainer>
          <Title>รีเซ็ทอุปกรณ์ (Unregister)</Title>
          <SubTitle>
            TVI Connect ทั้งหมดที่ทดสอบจะถูกล้างข้อมูล คืนค่าเริ่มต้น (เอา deviceID ออกจากอุปกรณ์)
          </SubTitle>
        </TContainer>
      </Top>
      <Bottom>
        <div style={{ marginRight: '16px', width: '18px' }} />
        <GreenButton disabled={!devices?.length} onClick={() => setOpenModal(true)}>
          Unregister
        </GreenButton>
      </Bottom>
      <Modal
        title="คุณต้องการรีเซ็ทอุปรณ์ TVI Connect"
        open={openModal}
        onOk={onUnregisterDevice}
        onCancel={() => setOpenModal(false)}
        okText="รีเซ็ท"
      >
        <div>
          TVI Connect ที่คุณต้องการ unregister ที่ทดสอบทั้งหมด จะถูกล้างข้อมูล และคืนค่าเริ่มต้น
        </div>
      </Modal>
    </Container>
  )
}

export default ResetTviConnectSection
