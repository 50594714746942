import _ from 'lodash'
import { UseQueryResult } from '@tanstack/react-query'
import { UserNotificationFilterResponse } from '@/services/notification/useUserNotificationFilter/type'

export function getFilterColumnOption(
  queryResult: UseQueryResult<UserNotificationFilterResponse, Error>,
  columnName: 'policyNumbers' | 'licensePlates',
) {
  return _.chain(queryResult.data?.[columnName])
    .map((o) => ({ text: o, value: o }))
    .sort()
    .value()
}
