import { CloseOutlined } from '@ant-design/icons'
import {
  Block,
  ChooseFileButton,
  Container,
  Desc,
  Space,
  InputTextArea,
  Select,
  Title,
  Upload,
} from './styles'
import EditActiveBonusModalHook from './EditActiveBonusModal.hook'
import Modal from '@/components/common/Modal'
import { Button } from 'antd'

export default function EditActiveBonusModal() {
  const {
    activeBonusModal,
    onCloseModal,
    selectPartnerProps,
    selectBranchProps,
    textAreaRemarkProps,
    uploadProps,
    isFormValidate,
    onSaveActivityDuration,
    loadingAdjustBonusPointMutation,
    isLoading,
  } = EditActiveBonusModalHook()

  return (
    <Modal
      open={activeBonusModal.open}
      title="แก้ไขคะแนน Well Point"
      closeIcon={
        <CloseOutlined
          data-testid="edit-activity-bonus-modal-close-button"
          onClick={() => {
            if (!isLoading) onCloseModal({ isEditSuccess: false })
          }}
        />
      }
      okText="บันทึก"
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={onSaveActivityDuration}
          disabled={!isFormValidate || loadingAdjustBonusPointMutation || isLoading}
          loading={loadingAdjustBonusPointMutation || isLoading}
          data-testid="edit-activity-bonus-modal-confirm-button"
        >
          บันทึก
        </Button>,
      ]}
    >
      <Container>
        <Block>
          <Title>Well Point พาร์ทเนอร์</Title>
          <Select
            showSearch
            placeholder="เลือก"
            data-testid="edit-activity-bonus-modal-select-partner"
            {...selectPartnerProps}
          />
        </Block>
        <Block>
          <Title>ชื่อสาขา</Title>
          <Select
            showSearch
            placeholder="เลือก"
            data-testid="edit-activity-bonus-modal-select-branch"
            {...selectBranchProps}
          />
        </Block>
        <Block>
          <Title>รายละเอียด</Title>
          <InputTextArea
            rows={4}
            placeholder="เขียนรายละเอียด"
            data-testid="edit-activity-bonus-modal-remark-textarea"
            {...textAreaRemarkProps}
          />
        </Block>
        <Block>
          <Title>รูปรายละเอียด</Title>
          <Upload
            listType="picture"
            data-testid="edit-activity-bonus-modal-upload-file"
            {...uploadProps}
          >
            <Space>
              <ChooseFileButton>เลือก</ChooseFileButton>
            </Space>
          </Upload>
          <Desc>รูปที่คุณจะอัปโหลด PNG, JPG, JPEG or PDF (สูงสุดไม่เกิน 10mb.)</Desc>
        </Block>
      </Container>
    </Modal>
  )
}
