import React from 'react'

import {
  Container,
  NotFoundContainer,
  NotFoundIcon,
  Search,
  SearchIcon,
  SearchLogo,
  Title,
} from './styles'
import SearchPolicyBoxHook, { SearchStateEnum } from './SearchPolicyBox.hook'
import CustomerProfile from '@/components/feature/PolicyDetailPage/CustomerProfile'
import PolicyCard from '@/components/feature/PolicyDetailPage/PolicyCard'
import PolicyDetailHeader from '@/components/feature/PolicyDetailPage/PolicyDetailHeader'
import { IMAGE_URL } from '@/constant/IMAGE'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import NotificationTab from '../NotificationTab'

type Props = {
  isTabShow: {
    notification: boolean
  }
}

function SearchPolicyBox({ isTabShow }: Props) {
  const { policy, handleSearch, isError, isLoading, searchState, handleSetSearchState } =
    SearchPolicyBoxHook()

  const isSearchFinish = searchState === SearchStateEnum.FINISH
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'กรมธรรม์',
      disabled: false,
      children: <PolicyCard isComponentLoading={isLoading} searchState={searchState} />,
    },
  ]
  if (isTabShow.notification) {
    items.push({
      key: '2',
      label: 'การแจ้งเตือน',
      children: <NotificationTab isComponentLoading={isLoading} searchState={searchState} />,
    })
  }

  return (
    <>
      <Container visible={!policy.planName}>
        <SearchLogo src={IMAGE_URL.searchIcon} />
        <Title>ค้นหาข้อมูลลูกค้า</Title>
        <Search
          data-testid="policy-detail-search-input"
          addonBefore={<SearchIcon />}
          placeholder="หมายเลขโทรศัพท์, ชื่อ-สกุล, เลขบัตรประชาชน, เลขกรมธรรม์"
          onSearch={(value) => handleSearch(value)}
        />
        {isError && searchState === SearchStateEnum.NONE ? (
          <NotFoundContainer>
            <NotFoundIcon src={IMAGE_URL.notFoundIcon} />
          </NotFoundContainer>
        ) : undefined}

        <CustomerProfile isComponentLoading={isLoading} searchState={searchState} />
        {isSearchFinish ? <Tabs defaultActiveKey="1" items={items} /> : undefined}
      </Container>
      <PolicyDetailHeader handleSetSearchState={handleSetSearchState} />
    </>
  )
}

export default SearchPolicyBox
