import React, { useEffect, useState } from 'react'
import { useErrorLogPagination } from '@/services/dopa/useErrorLogPagination'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { toThaiLocaleDateDashAndTime, toThaiLocaleDateString } from '@/libs/date'
import { SorterResult, SortOrder } from 'antd/lib/table/interface'
import { SorterEnum } from '@/constant/USER'
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs'
import { getFilterColumnOption, mapDopaErrorLogFrom } from './helper'
import { runNumber } from '@/components/common/Table/helper'

const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

export default function ErrorLogTableHook() {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const handlePaginate = (page: number, pageSize: number) => {
    setPage(page)
    setLimit(pageSize)
  }
  const errorLog = useErrorLogPagination({ page, limit, search, sortBy })
  const filterColFormOption = getFilterColumnOption(errorLog, 'from')
  const filterColRegisterDateOption = getFilterColumnOption(errorLog, 'registerDate')
  const columns: ColumnsType<{}> = [
    {
      title: 'ลำดับ',
      width: 75,
      align: 'center',
      render: (_, __, index) => runNumber(index, page, limit),
    },
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'registerDate',
      render: (value: string) => toThaiLocaleDateDashAndTime(value),
      filters: filterColRegisterDateOption,
      onFilter: (value, record: any) => record.registerDate.startsWith(value as string),
      filterSearch: true,
    },
    { title: 'เลขบัตรประจำตัวประชาชน', dataIndex: 'identityId' },
    {
      title: 'ใช้ SERVICE',
      dataIndex: 'from',
      render: mapDopaErrorLogFrom,
      filters: filterColFormOption,
      onFilter: (value, record: any) => record.from.indexOf(value as string) === 0,
    },
    { title: 'REQUEST ID', dataIndex: 'requestId' },
    { title: 'ชื่อผู้เอาประกันที่กรอก', dataIndex: 'firstName' },
    { title: 'นามสกุลผู้เอาประกันที่กรอก', dataIndex: 'lastName' },
    {
      title: 'วันเดือนปีเกิดที่กรอก',
      dataIndex: 'registerDateOfBirth',
      render: (date: string) => toThaiLocaleDateString({ date }),
    },
    { title: 'ERROR MESSAGE', dataIndex: 'errorDescription' },
  ]
  const handleSortBy = (field?: React.Key | readonly React.Key[], order?: SortOrder) => {
    switch (order) {
      case SorterEnum.ASC:
        return `${field}:ASC`
      case SorterEnum.DESC:
        return `${field}:DESC`
      default:
        return ''
    }
  }
  const onTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<{}> | SorterResult<{}>[],
  ) => {
    const { current, pageSize } = pagination
    const sort = Array.isArray(sorter) ? sorter[0] : sorter
    const { field, order } = sort || { field: '', order: '' }
    const sortBy = handleSortBy(field, order)
    setPage(current || 1)
    setLimit(pageSize || 10)
    setSortBy(sortBy)
  }
  useEffect(() => {
    const subscription = inputChange$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        setSearch(value)
        setPage(1)
      })
    return () => {
      inputChange.next('')
      return subscription.unsubscribe()
    }
  }, [])
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    inputChange.next(value)
  }
  return {
    errorLog,
    columns,
    onTableChange,
    onSearch,
    handlePaginate,
  }
}
