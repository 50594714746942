import { Block, Container, Title, Input, Upload, Desc, Desc2, ChooseFileButton } from './styles'
import EditActivityDurationModalHook from './EditActivityDurationModal.hook'
import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useEffect } from 'react'
import Modal from '@/components/common/Modal'

function EditActivityDurationModal({
  handleRefetchBackofficeGetUserExerciseStat,
}: {
  handleRefetchBackofficeGetUserExerciseStat: () => void
}) {
  const {
    editActivityDurationModal,
    step,
    activityDuration,
    handleUploadProps,
    isFormValidate,
    handleRemoveFileProps,
    onCloseModal,
    onChangeStep,
    onChangeActivityDuration,
    onHandlerDefaultValue,
    onHandlerSaveActivityDuration,
    loadingBackofficeAdjustDailyExerciseDataMutation,
  } = EditActivityDurationModalHook()

  useEffect(() => {
    if (editActivityDurationModal.isEditSuccess) {
      handleRefetchBackofficeGetUserExerciseStat()
    }
  }, [editActivityDurationModal.isEditSuccess, handleRefetchBackofficeGetUserExerciseStat])

  return (
    <Modal
      open={editActivityDurationModal.open}
      title="แก้ไขข้อมูลออกกำลังกาย"
      cancelText="ใช้ข้อมูลจากนาฬิกา"
      okText="บันทึก"
      footer={[
        <Button
          key="sync-watch-data"
          onClick={onHandlerDefaultValue}
          data-testid="edit-activity-duration-modal-cancel-button"
        >
          ใช้ข้อมูลจากนาฬิกา
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onHandlerSaveActivityDuration}
          disabled={!isFormValidate}
          loading={loadingBackofficeAdjustDailyExerciseDataMutation}
          data-testid="edit-activity-duration-modal-confirm-button"
        >
          บันทึก
        </Button>,
      ]}
      closeIcon={<CloseOutlined onClick={() => onCloseModal({ isEditSuccess: false })} />}
      data-testid="edit-activity-duration-modal"
    >
      <Container>
        <Block>
          <Title>จำนวนก้าว</Title>
          <Input
            value={step}
            onChange={(event) => onChangeStep(event.target.value)}
            data-testid="edit-activity-duration-modal-edit-step-input"
          />
          <Desc2>
            ข้อมูลเดิมที่ได้จากนาฬิกา {editActivityDurationModal.step?.toLocaleString()} ก้าว
          </Desc2>
        </Block>
        <Block>
          <Title>เวลาออกกำลังกาย</Title>
          <Input
            value={activityDuration}
            onChange={(event) => onChangeActivityDuration(event.target.value)}
            data-testid="edit-activity-duration-modal-edit-duration-input"
          />
          <Desc2>
            ข้อมูลเดิมที่ได้จากนาฬิกา {editActivityDurationModal.activityDuration?.toLocaleString()}{' '}
            นาที
          </Desc2>
        </Block>
        <Block>
          {/* TODO: validate file type after chose / before upload. */}
          <Title>รูปรายละเอียด</Title>
          <Upload maxCount={1} onRemove={handleRemoveFileProps} {...handleUploadProps}>
            <ChooseFileButton data-testid="edit-activity-duration-modal-upload-file-button">
              เลือก
            </ChooseFileButton>
          </Upload>
        </Block>
        <Desc>รูปที่คุณจะอัปโหลด PNG, JPG, JPEG or PDF (สูงสุดไม่เกิน 10mb.)</Desc>
      </Container>
    </Modal>
  )
}

export default EditActivityDurationModal
