import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'
import { useUserNotification } from '@/services/notification/useUserNotification'
import { IMAGE_URL } from '@/constant/IMAGE'
import { ImageIcon } from '@/components/feature/UserPermissionPage/components/ModalAddTeamMember/styles'
import { getFilterColumnOption } from './helper'
import { useUserNotificationFilter } from '@/services/notification/useUserNotificationFilter'
import { toThaiLocaleShortDateAndTime } from '@/libs/date'
import { runNumber } from '@/components/common/Table/helper'
import { UserNotificationPaginationParams } from '@/services/notification/useUserNotification/types'
import dayjs from 'dayjs'
import { TablePaginationConfig } from 'antd'
import _ from 'lodash'
import { UserSelectStyle } from '@/components/common/Table/styles'

type D = Date | undefined
type P = UserNotificationPaginationParams
type S = string | undefined
type N = number | undefined

export default function NotificationTableHook({ userId }: { userId: string }) {
  const now = dayjs()
  const today = now.toDate()
  const ninetyDaysAgo = now.subtract(90, 'days').toDate()
  const [page, setPage] = useState<N>(1)
  const [limit, setLimit] = useState<N>(10)
  const [search, setSearch] = useState<S>(undefined)
  const [language, setLanguage] = useState('th')
  const [startDate, setStartDate] = useState<D>(ninetyDaysAgo)
  const [endDate, setEndDate] = useState<D>(today)
  const [period, setPeriod] = useState<'90DaysAgo' | 'all' | 'custom'>('90DaysAgo')
  const [policyNumbersFilter, setPolicyNumbersFilter] = useState<string[]>([])
  const [licensePlatesFilter, setLicensePlatesFilter] = useState<string[]>([])
  const param: P = {
    query: { userId, startDate, endDate, policyNumbersFilter, licensePlatesFilter, search },
    pagination: { page, limit },
  }
  const userNotification = useUserNotification(param)
  // filter of userNotification from server
  const remoteFilter = useUserNotificationFilter({ userId, startDate, endDate })
  const filterOptPNO = getFilterColumnOption(remoteFilter, 'policyNumbers')
  const filterOptLicensePlate = getFilterColumnOption(remoteFilter, 'licensePlates')
  const columns: ColumnsType<{}> = [
    {
      title: 'ลำดับ',
      width: 75,
      align: 'center',
      render: (_, __, index) => runNumber(index, page || 1, limit || 10),
    },
    {
      title: 'เลขที่ธรมธรรม์',
      dataIndex: 'policyNumber',
      filters: filterOptPNO,
      className: UserSelectStyle,
    },
    {
      title: 'ทะเบียนรถ',
      dataIndex: 'licensePlate',
      filters: filterOptLicensePlate,
      className: UserSelectStyle,
      width: '110px',
    },
    language === 'th'
      ? {
          title: 'ข้อความที่ส่ง',
          dataIndex: 'notificationMessageTh',
          className: UserSelectStyle,
        }
      : {
          title: 'ข้อความที่ส่ง',
          dataIndex: 'notificationMessageEn',
          className: UserSelectStyle,
        },
    {
      title: 'วันเวลาที่ส่งแจ้งเตือน',
      dataIndex: 'notificationDate',
      width: '150px',
      className: UserSelectStyle,
      render: (value) => toThaiLocaleShortDateAndTime(value),
    },
    {
      title: 'เปิดอ่านแล้ว',
      dataIndex: 'isRead',
      render: (value) => (
        <ImageIcon src={value ? IMAGE_URL.circleSuccessIcon : IMAGE_URL.circleSecondaryIcon} />
      ),
      width: '100px',
    },
  ]
  const toggle = (name: 'language' | 'period') => {
    if (name === 'language') {
      return () => {
        if (language === 'th') setLanguage('en')
        if (language === 'en') setLanguage('th')
      }
    }
    if (name === 'period') {
      return (val: string | number) => {
        if (val === '90DaysAgo') {
          setStartDate(ninetyDaysAgo)
          setEndDate(today)
          setPeriod('90DaysAgo')
          setPage(1)
        }
        if (val === 'all') {
          setStartDate(undefined)
          setEndDate(undefined)
          setPeriod('all')
          setPage(1)
        }
      }
    }
  }
  const onSearch = (event: any) => {
    if (event.key === 'Enter') {
      setSearch(event.target.value)
      setPage(1)
    }
  }
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, any | null>,
  ) => {
    setPage(pagination.current)
    setLimit(pagination.pageSize)
    filters.licensePlate ? setLicensePlatesFilter(filters.licensePlate) : setLicensePlatesFilter([])
    filters.policyNumber ? setPolicyNumbersFilter(filters.policyNumber) : setPolicyNumbersFilter([])
  }
  const onDatePickerChange = (values: any) => {
    if (_.isEmpty(values)) {
      setPeriod('all')
      setStartDate(undefined)
      setEndDate(undefined)
      setPage(1)
    } else {
      setPeriod('custom')
      setStartDate(values[0].toDate())
      setEndDate(values[1].toDate())
      setPage(1)
    }
  }
  return {
    columns,
    userNotification,
    onSearch,
    onTableChange,
    toggle,
    onDatePickerChange,
    setSearch,
    search,
  }
}
