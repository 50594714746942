import { ColumnType } from 'antd/lib/table'
import { DeleteIcon } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import { CheckIcon } from './styles'
import { useEffect, useState } from 'react'
import message from '@/libs/message'
import { TviConnectDeviceType } from '../types'

const CheckTviConnectTableHook = ({
  data,
  removeDevice,
}: {
  data: TviConnectDeviceType[]
  removeDevice: (imei: string) => void
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isShowResetReasonColumn, setIsShowResetReasonColumn] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<{
    imei: string
    serialNumber: string
  } | null>(null)
  const columns: ColumnType<any>[] = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      align: 'center',
      render: (_value, _record, number) => {
        return number + 1
      },
    },
    {
      title: 'IMEI',
      key: 'imei',
      dataIndex: 'imei',
    },
    {
      title: 'SERIAL NO.',
      key: 'serialNumber',
      dataIndex: 'serialNumber',
    },
    {
      title: 'หมายเหตุจากการระบุอุปกรณ์',
      key: 'reason',
      dataIndex: 'reason',
    },
    isShowResetReasonColumn
      ? {
          title: 'หมายเหตุจากการรีเซ็ทอุปกรณ์',
          render: (data) => {
            return data?.resetReason || undefined
          },
        }
      : { width: 0 },
    {
      title: 'เปิด',
      key: 'on',
      dataIndex: 'on',
      width: 50,
      render: (on) => {
        return <CheckIcon isGreen={on} />
      },
    },
    {
      title: 'ปิด',
      key: 'off',
      dataIndex: 'off',
      width: 50,
      render: (off) => {
        return <CheckIcon isGreen={off} />
      },
    },
    {
      title: '',
      render: (data, _record, index) => {
        return data?.reason ? (
          <DeleteIcon
            src={IMAGE_URL.trashIcon}
            onClick={() => onOpenModal(data.imei, data.serialNumber)}
          />
        ) : undefined
      },
    },
  ]

  const onOpenModal = (imei: string, serialNumber: string) => {
    setSelectedDevice({ imei, serialNumber })
    setIsModalOpen(true)
  }

  const onRemoveDevice = () => {
    if (!selectedDevice) return

    removeDevice(selectedDevice?.imei)
    setSelectedDevice(null)
    setIsModalOpen(false)
    message.success('ลบข้อมูลสำเร็จ')
  }

  useEffect(() => {
    const isShowResetReasonColumn = data.some((device) => device.resetReason)
    setIsShowResetReasonColumn(isShowResetReasonColumn)
  }, [data])

  return {
    columns,
    isModalOpen,
    selectedDevice,
    setIsModalOpen,
    onRemoveDevice,
    setIsShowResetReasonColumn,
  }
}

export default CheckTviConnectTableHook
