import config from '@/config'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'
import {
  UserNotificationPaginationParams,
  UserNotificationPaginationResponse,
  UserNotificationTableQuery,
} from './types'
import { AxiosRequestConfig } from 'axios'
import { PaginateQuery } from '@/services/common'
import { toDateDash } from '@/libs/date'
import _ from 'lodash'

const fetchUserNotification = async (
  query: UserNotificationTableQuery,
  pagination?: PaginateQuery,
) => {
  const { startDate, endDate, policyNumbersFilter, licensePlatesFilter, search } = query
  const url = `${config.restUrl}/v1/backoffice/user/${query.userId}/notification`
  const reqConf: AxiosRequestConfig = {
    params: {
      ...query,
      ...pagination,
      userId: undefined,
      policyNumbersFilter: undefined,
      licensePlatesFilter: undefined,
      search: undefined,
      startDate: startDate ? toDateDash(startDate) : undefined,
      endDate: endDate ? toDateDash(endDate) : undefined,
      filterPolicyNumber: _.isEmpty(policyNumbersFilter) ? undefined : policyNumbersFilter,
      filterLicensePlate: _.isEmpty(licensePlatesFilter) ? undefined : licensePlatesFilter,
      searchTerm: search ? search : undefined,
    },
    paramsSerializer: { indexes: null },
  }
  const response = await httpClientPrivate.get<UserNotificationPaginationResponse>(url, reqConf)
  return response.data
}

const useUserNotification = (param: UserNotificationPaginationParams) => {
  const query = useQuery({
    queryKey: ['useUserNotification', param.query, param?.pagination],
    queryFn: async () => fetchUserNotification(param.query, param?.pagination),
    placeholderData: keepPreviousData,
  })
  return { ...query }
}

export { fetchUserNotification, useUserNotification }
