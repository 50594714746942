import { Tabs } from 'antd'
import SearchPolicyBox from './SearchPolicyBox'
import PolicyDetailPageHook from './PolicyDetailPage.hook'
import { Container } from './styles'
import { PolicyGroupEnum } from '@/constant/POLICY_GROUP'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PAGE, BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { PolicyDetailContext } from '@/contexts'
import HistoryCarUsageDetail from './HistoryCarUsageDetail/HistoryCarUsageDetail'

function PolicyDetailPage() {
  const {
    tabItems,
    healthTabItems,
    user,
    policy,
    tabActiveKey,
    handleSetTabActiveKey,
    getPolicyByPolicyNumber,
    isComponentLoading,
    setSelectCoverageId,
    selectedCoverageId,
  } = PolicyDetailPageHook()
  const isHealthPolicy = policy.policyGroup === PolicyGroupEnum.ACTIVE_HEALTH
  const hasValidPermissions = validatePerm([BACKOFFICE_USER_PAGE.HLT0101], user.pages)
  const hasPolicyNumber = !!policy.policyNumber
  const shouldShowHealthTabs = isHealthPolicy && hasValidPermissions
  const shouldShowTabs = shouldShowHealthTabs || hasPolicyNumber
  const shouldShowNotificationTab = validatePerm(
    [BACKOFFICE_USER_PERMISSION.CUS010104],
    user.permissions,
  )

  // TODO: add skeleton component and handle in single component
  return (
    <PolicyDetailContext.Provider
      value={{
        handleSetTabActiveKey,
        getPolicyByPolicyNumber,
        isComponentLoading,
        setSelectCoverageId,
        selectedCoverageId,
      }}
    >
      <Container visible={!selectedCoverageId}>
        <SearchPolicyBox isTabShow={{ notification: shouldShowNotificationTab }} />
        {shouldShowTabs && (
          <Tabs items={shouldShowHealthTabs ? healthTabItems : tabItems} activeKey={tabActiveKey} />
        )}
      </Container>
      {selectedCoverageId && <HistoryCarUsageDetail />}
    </PolicyDetailContext.Provider>
  )
}

export default PolicyDetailPage
