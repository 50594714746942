import config from '@/config'
import { UserNotificationFilterQuery, UserNotificationFilterResponse } from './type'
import { httpClientPrivate } from '@/libs/httpClient'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { toDateDash } from '@/libs/date'

const fetchUserNotificationFilter = async (query: UserNotificationFilterQuery) => {
  const { userId, startDate, endDate } = query
  const url = `${config.restUrl}/v1/backoffice/user/${userId}/notification/filter`
  const reqConf: AxiosRequestConfig = {
    params: {
      ...query,
      userId: undefined,
      startDate: startDate ? toDateDash(startDate) : undefined,
      endDate: endDate ? toDateDash(endDate) : undefined,
    },
    paramsSerializer: { indexes: null },
  }
  const response = await httpClientPrivate.get<UserNotificationFilterResponse>(url, reqConf)
  return response.data
}

const useUserNotificationFilter = (param: UserNotificationFilterQuery) => {
  const query = useQuery({
    queryKey: ['useUerNotificationFilter', param],
    queryFn: async () => fetchUserNotificationFilter(param),
    placeholderData: keepPreviousData,
  })
  return { ...query }
}

export { fetchUserNotificationFilter, useUserNotificationFilter }
