import { UseQueryResult } from '@tanstack/react-query'
import { ErrorLogPaginationResponse } from '@/services/dopa/useErrorLogPagination/types'
import _ from 'lodash'
import { toDateDash, toThaiLocaleDateDash } from '@/libs/date'

export function mapDopaErrorLogFrom(value: string): string {
  if (value === 'super-app-register') return 'ลงทะเบียน'
  if (value === 'super-app-buy-pa-active-shield') return 'ซื้อประกัน PA Active shield'
  if (value === 'super-app-buy-cancer') return 'ซื้อประกัน Cancer'
  return 'n/a'
}

export function getFilterColumnOption(
  queryResult: UseQueryResult<ErrorLogPaginationResponse, Error>,
  columnName: 'from' | 'registerDate',
): { text: string; value: string }[] {
  const data = queryResult.data?.data ?? []

  if (columnName === 'registerDate') {
    return _.chain(data)
      .map((o) => ({
        text: toThaiLocaleDateDash(o.registerDate),
        value: toDateDash(o.registerDate),
      }))
      .uniqBy('value')
      .value()
  }

  return _.chain(data)
    .map((o) => ({
      text: mapDopaErrorLogFrom(o[columnName]),
      value: o[columnName],
    }))
    .uniqBy('value')
    .value()
}
